import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import styles from "./footer.module.css"

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerInner}>
      <small>© mokajima.com</small>
      <ul className={styles.footerNav}>
        <li className={styles.footerNavItem}>
          <a href="https://github.com/mokajima/">
            <FontAwesomeIcon icon={faGithub} size="lg" />
          </a>
        </li>
        <li className={styles.footerNavItem}>
          <a href="https://twitter.com/mokajima85z">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer
